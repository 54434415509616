import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
import _imports_0 from '@/assets/images/notBg2.png';
const _hoisted_1 = {
  class: "contract-order"
};
const _hoisted_2 = {
  class: "isBgs"
};
const _hoisted_3 = {
  class: "content"
};
const _hoisted_4 = {
  class: "label"
};
const _hoisted_5 = {
  class: "num"
};
const _hoisted_6 = {
  class: "content acea-row row-center-wrapper"
};
const _hoisted_7 = ["onClick"];
import { router } from '@/router';
import { computed, ref, watch } from 'vue';
import { contractOrder2, getBalance } from '@/http';
import { useUserStore } from "@/stores/user";
import contOrder from '@/components/contOrder/contOrder.vue';
import { useRoute } from "vue-router";
export default {
  __name: 'defendOrder',
  setup(__props) {
    const total = ref(0);
    const pageSize = ref(5);
    const list = ref([]);
    const page = ref([1, 1]);
    const active = ref(0);
    const loading = ref([false, false]);
    const finished = ref([false, false]);
    const userStore = useUserStore();
    /**  @property{Array} tabList = [加密货币,外汇,金子,期货] */
    const tabList = computed(() => userStore.tabList);
    const route = useRoute();
    const typeTos = route.query.order ? parseInt(route.query.order) : 0;
    let tabActive = ref(typeTos);
    const isContOrder = ref(null);
    const setTabs = index => {
      tabActive.value = index;
      if (isContOrder.value) {
        isContOrder.value.setTabs(index);
      }
    };
    let toBg = ref(false);
    const changes = e => {
      toBg.value = e;
    };
    let show = ref(false);
    const scroll = e => {
      show.value = e.scrollTop > 10;
    };
    const finist = () => {
      page.value = [1, 1];
      _contractOrder();
    };
    const changeList = net => {
      console.log(net);
      page.value[active.value] = net;
      _contractOrder();
    };
    const _contractOrder = () => {
      contractOrder2({
        state: active.value,
        page: page.value[active.value],
        perPage: pageSize.value
      }).then(({
        data
      }) => {
        list.value = initList(data.data);
        total.value = data.total;
      });
    };
    const initList = dataList => {
      let to = null;
      console.log(tabActive.value);
      if (tabActive.value === 0) {
        dataList.forEach(item => {
          console.log(item['product'].category);
        });
        to = dataList.filter(item => item['product'].category === 0 || item['product'].category === 2);
      } else {
        to = dataList.filter(item => item['product'].category === 3);
      }
      return to;
    };
    const back = () => {
      router.go(-1);
    };
    _contractOrder();
    watch(active, newVal => {
      console.log(newVal);
      _contractOrder();
    });
    const userData = ref({});
    const getData = () => {
      getBalance().then(res => {
        console.log(res);
        userData.value = res.data;
      });
    };
    getData();
    return (_ctx, _cache) => {
      const _component_van_sticky = _resolveComponent("van-sticky");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("模拟账户余额")), 1), _createElementVNode("div", _hoisted_5, [_createTextVNode(_toDisplayString(_ctx.$public.keepFulls(userData.value.balance_virtual) || 0) + " ", 1), _cache[0] || (_cache[0] = _createElementVNode("span", null, "USDT", -1))])])]), _createVNode(_component_van_sticky, {
        onChange: changes
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: _normalizeClass(["home-tab", {
            'homeBgs': _unref(toBg)
          }])
        }, [_createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabList.value, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["items", {
              'active': _unref(tabActive) === item.value
            }]),
            style: {
              "margin-right": "10px"
            },
            onClick: $event => setTabs(item.value),
            key: index
          }, _toDisplayString(_ctx.$t(item.name)), 11, _hoisted_7);
        }), 128))])], 2)]),
        _: 1
      }), _createVNode(contOrder, {
        types: _unref(tabActive),
        "show-defends": true,
        ref_key: "isContOrder",
        ref: isContOrder
      }, null, 8, ["types"])]);
    };
  }
};